import React, { useState, useEffect, useRef } from "react";
import { Form, Container, Table } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";
import CustomButton from "./CustomButton";
import { toast } from "react-toastify";
import useClient from "../hooks/useClient";
import useSubscription from "../hooks/useSubscription";
import useSubscriptions from "../hooks/useSubscriptions";
import useProducts from "../hooks/useProducts";
import { authenticatedPostRequest } from "../requests/apiRequests";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import crypto from "crypto-browserify";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

const styles = {
  formContainer: { maxWidth: "80vw", paddingTop: 10 },
  heading: { textAlign: "center", marginBottom: "30px" },
  packageContainer: { marginBottom: "20px" },
  body: {
    padding: "100px 50px 50px 50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  heading: {
    fontSize: "36px",
    color: "#fa0505", // Text color for canceled message
    marginBottom: "20px",
  },
  description: {
    fontSize: "18px",
    marginBottom: "20px",
  },
  icon: {
    fontSize: "48px",
    color: "#fa0505", // Color of the cancel icon
    marginBottom: "20px",
  },
  error: {
    color: "red",
  },
};

const generateSignature = (data, passPhrase = null) => {
  let pfOutput = "";
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] !== "") {
        pfOutput += `${key}=${encodeURIComponent(data[key].trim()).replace(
          /%20/g,
          "+"
        )}&`;
      }
    }
  }
  let getString = pfOutput.slice(0, -1);
  if (passPhrase !== null) {
    getString += `&passphrase=${encodeURIComponent(passPhrase.trim()).replace(
      /%20/g,
      "+"
    )}`;
  }
  return crypto.createHash("md5").update(getString).digest("hex");
};

function SubscribeForm({ product }) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAuthUser()();
  const authHeader = useAuthHeader();

  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [cell, setCell] = useState("");
  const [email, setEmail] = useState("");
  const [defaultProduct, setDefaultProduct] = useState(product);
  const [additionalItems, setAdditionalItems] = useState([]);
  const [errors, setErrors] = useState({});
  const [checkedItems, setCheckedItems] = useState({}); // Track the checked status of items
  const datenow = Date.now();
  const [paymentId, setPaymentId] = useState(datenow); // Track the checked status of items

  const userId = user?.userID;
  const { client } = useClient(authHeader());
  const { subscriptions } = useSubscription(userId, product.replace(/\b\w/g, (char) => char.toUpperCase()).replace(/-(.)/g, (_, char) => ' ' + char.toUpperCase()).replace(/'s /g, "'s ").replace(/'S /g, "'s "), authHeader());
  const { subscriptionss } = useSubscriptions(authHeader(), 5);
  const { products } = useProducts(authHeader());
  const myDataRef = useRef(null);


  const addAdditionalItem = (item, price) => {
    const isItemExists = additionalItems.some((existingItem) => existingItem.item === item);
    if (!isItemExists) {
      setAdditionalItems((prevItems) => [...prevItems, { item, price }]);
      setCheckedItems((prev) => ({ ...prev, [item]: true }));
    }
  };

  const removeAdditionalItem = (item) => {
    setAdditionalItems((prevItems) =>
      prevItems.filter((prevItem) => prevItem.item !== item)
    );
    setCheckedItems((prev) => ({ ...prev, [item]: false }));
  };

  const packageDetails = [...additionalItems];

  const calculateTotalPrice = () => {
    return packageDetails.reduce((total, detail) => total + detail.price, 0);
  };

  const initializeMyDataRef = () => {
    
    const totalAmount = calculateTotalPrice();

      // merchant_id: "10000100",
      // merchant_key: "46f0cd694581a",

      // merchant_id: "12920595",
      // merchant_key: "tm1f3kg4naztk",

      myDataRef.current = {
        merchant_id: "12920595",
        merchant_key: "tm1f3kg4naztk",
        return_url: "https://simplifydrive.co.za/client/return/",
        cancel_url: "https://simplifydrive.co.za/client/cancel/",
        notify_url: "https://simplifydrive.co.za/api/payfast/notify",
        name_first: fname,
        name_last: lname,
        email_address: email,
        m_payment_id: String(paymentId),
        amount: String(totalAmount.toFixed(2)),
        item_name: "#" + paymentId,
        custom_str1: String(userId),
        custom_str2: "Subscription",
      };

      // const myPassphrase = "jt7NOE43FZPn";
      const myPassphrase = "SimplestDrive_1";
      myDataRef.current.signature = generateSignature(
        myDataRef.current,
        myPassphrase
      );
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const productType = defaultProduct;

    if (productType) {
      products.forEach(product => {
        if (product.name.toLowerCase().replace(/\s+/g, '-') === productType) {
          addAdditionalItem(product.name, parseInt(product.price));
        }
      });
    }

    if (subscriptions) {
      if (subscriptions.client_ID === userId) {
        const subscriptionDate = new Date(subscriptions.date_subscribed);
        const currentDate = new Date();
        const differenceInMilliseconds = currentDate - subscriptionDate;
        const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);

        if (differenceInDays <= 30 && subscriptions.paid === 1) {

          if(product === "Learner's Simulation Test"){
            navigate("/client/learners-tests/test1");
          }else if(product === "Training Videos"){
            navigate("/client/training-videos");
          }
          
        }
      }
    }

    const myClient = client.find((client) => client.user_ID === userId);
    if (myClient) {
      setEmail(myClient.email);
      setName(`${myClient.name} ${myClient.surname}`);
      setFName(myClient.name);
      setLName(myClient.surname);
      setCell(myClient.cell_number);
    }

    if (step === 2) {
      initializeMyDataRef();
    }
  }, [step, client, userId, subscriptions, location.search, subscriptionss, products]);

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  const handleBack = (e) => {
    e.preventDefault();
    prevStep();
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (step === 1) {
      initializeMyDataRef();
    }
    nextStep();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      for (const item of additionalItems) {
        const formFields = {
          client_ID: userId,
          subscription_name: item.item,
          client_email: email,
          client_name: name,
          paid: 0,
          m_payment_id: myDataRef.current.m_payment_id,
          price: item.price.toFixed(2),
        };

        const response = await authenticatedPostRequest(
          "/clients/subscribe",
          formFields,
          authHeader()
        );

        if (response.status !== 200) {
          toast.error(`There is an error in ${item.item} subscriptions!`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
        }
      }

      const payFastForm = document.getElementById("payFastForm");
      if (payFastForm) {
        payFastForm.submit();
      }
    } catch (error) {
      console.error("Error while sending requests:", error);
      toast.error("An error occurred while sending requests", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    }
  };

  switch (step) {
    case 1:
      return (
        <>
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              style={{
                display: "flex",
                width: "100vw",
                height: "100%",
                padding: "20px 10px",
                margin: "10px 10px",
                "@media (min-width: 768px)": {
                  width: "60vw",
                },
              }}
            >
              <h2>{product ? product.replace(/\b\w/g, (char) => char.toUpperCase()).replace(/-(.)/g, (_, char) => ' ' + char.toUpperCase()).replace(/'s /g, "'s ").replace(/'S /g, "'s ")  + ' Subscription' : 'Subscription'}</h2>
              <h4 className="mt-3">Personal Information</h4>
              <Form.Group controlId="name" className="mb-3">
                <Form.Label>Name:</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  required
                  placeholder="Name"
                />
              </Form.Group>
              <Form.Group controlId="contacts:" className="mb-3">
                <Form.Label>Cell Number:</Form.Label>
                <Form.Control
                  type="text"
                  value={cell}
                  required
                  placeholder="Cell Number"
                />
              </Form.Group>
              <Form.Group controlId="email" className="mb-3">
                <Form.Label>Email Address:</Form.Label>
                <Form.Control
                  type="text"
                  value={email}
                  required
                  placeholder="Email"
                />
              </Form.Group>

              <h4 className="mt-3">Products</h4>
              <p>Please note that {product ? product.replace(/\b\w/g, (char) => char.toUpperCase()).replace(/-(.)/g, (_, char) => ' ' + char.toUpperCase()).replace(/'s /g, "'s ").replace(/'S /g, "'s ")  : ''} will be selected for you as the default product, you can add more products by clicking on the checkboxes.</p>
              {products.map((product, i) => {
                let print = 1;

                // if(subscriptionss) {
                //   subscriptionss.forEach(subscriptions => {
                //     if (subscriptions && subscriptions.client_ID === userId && subscriptions.subscription_name === product.name) {
                //         const subscriptionDate = new Date(subscriptions.date_subscribed);
                //         const currentDate = new Date();
                //         const differenceInMilliseconds = currentDate - subscriptionDate;
                //         const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);

                //         if (differenceInDays <= 30 && subscriptions.paid === 1) {
                //           print = 0;
                //         }
                //     }
                // });

                // }

                return print ? (
                    <Form.Group className="mb-3" id={`product-${i}`} key={i}>
                        <Form.Check
                            type="checkbox"
                            id={`product-check-${i}`}
                            label={
                                <label
                                    htmlFor={`product-check-${i}`}
                                    style={{ cursor: "pointer" }}
                                >
                                    {`${product.name} (R${product.price.toFixed(2)})`}
                                </label>
                            }
                            checked={!!checkedItems[product.name]}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    addAdditionalItem(
                                        product.name,
                                        parseFloat(product.price.toFixed(2))
                                    );
                                } else {
                                    removeAdditionalItem(product.name);
                                }
                            }}
                        />
                    </Form.Group>
                ) : null;
            })}

              <Form.Group>
                <CustomButton
                  text={"Confirm details"}
                  style={styles.rightButton}
                  onClick={handleNext}
                />
              </Form.Group>
            </Card>
          </Container>
        </>
      );

    case 2:
      return (
        <>
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              style={{
                display: "flex",
                width: "100vw",
                height: "100%",
                padding: "20px 10px",
                margin: "10px 10px",
                "@media (min-width: 768px)": {
                  width: "60vw",
                },
              }}
            >
              <h2>{product ? product.replace(/\b\w/g, (char) => char.toUpperCase()).replace(/-(.)/g, (_, char) => ' ' + char.toUpperCase()).replace(/'s /g, "'s ").replace(/'S /g, "'s ")  + ' Subscription Prices' : 'Subscription Prices'}</h2>
              <p>You are about to pay for {product ? product.replace(/\b\w/g, (char) => char.toUpperCase()).replace(/-(.)/g, (_, char) => ' ' + char.toUpperCase()).replace(/'s /g, "'s ").replace(/'S /g, "'s ")  : ''} to <strong>SimplifyDrive</strong>. You will re redirected to payfast gateway to complete the transaction. Please click on "Pay Now" button to confirm payment.</p>
              <Form.Group>
                
              <button className="btn btn-danger sm my-3" onClick={() => window.location.reload()}> Back to options </button>

              </Form.Group>

              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Price</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {packageDetails.map((detail, index) => (
                    <tr key={index}>
                      <td>{detail.item}</td>
                      <td>{`R ${detail.price.toFixed(2)}`}</td>
                      <td>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          style={{ cursor: "pointer", color: "red" }}
                          onClick={() => removeAdditionalItem(detail.item)}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <th>Total</th>
                    <th>{`R ${calculateTotalPrice().toFixed(2)}`}</th>
                    <th></th>
                  </tr>
                </tbody>
              </Table>

              <Form.Group>
                <CustomButton
                  text={"Pay Now"}
                  style={styles.rightButton}
                  onClick={handleSubmit}
                />
              </Form.Group>

              {myDataRef.current && (() => {
                let htmlForm = `<form id="payFastForm" action="https://www.payfast.co.za/eng/process" method="post">`;
                for (let key in myDataRef.current) {
                  if (myDataRef.current.hasOwnProperty(key)) {
                    const value = String(myDataRef.current[key]);
                    if (value !== "") {
                      htmlForm += `<input name="${key}" type="hidden" value="${value.trim()}" />`;
                    }
                  }
                }
                htmlForm += '</form>';
                return <div dangerouslySetInnerHTML={{ __html: htmlForm }} />;
              })()}
            </Card>
          </Container>
        </>
      );

    default:
      return null;
  }
}

export default SubscribeForm;