// Constants
export const UNAUTH_TESTTIME = 5 * 60; //time in seconds for unauthenticated users
export const TESTTIME = 60 * 60; //time in seconds for authenticated users
export const RSM_PASS_PERCENTAGE = 80; //Road Signs and Markings Passing Percentage
export const RR_PASS_PERCENTAGE = 83;  //Rules Of The Road Passing Percentage
export const VC_PASS_PERCENTAGE = 85;  //Vehicle Control Passing Percentage
//API URL
// export const API_BASE_URL = 'http://localhost:3000/api';
export const API_BASE_URL = 'https://simplifydrive.co.za/api';
//relative routes
export const BASE_URL = window.location.origin;
export const CLIENT_RELATIVE_PATH = "/client";
export const SCHOOL_RELATIVE_PATH = "/driving-school";
export const ADMIN_RELATIVE_PATH = "/admin";
// export const CLIENT_RELATIVE_PATH = genRandomPath(10); //no refresh path
