import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PrivateRoute from "./pages/PrivateRoute";
import Home from "./pages/Home";
import Schools from "./pages/Schools";
import Booking from "./pages/client/Booking";
import BookingLicence from "./pages/client/BookingLicence";
import QuoteLearners from "./pages/client/QuoteLearners";
import QuoteLicence from "./pages/client/QuoteLicence";
import AboutSchool from "./pages/AboutSchool";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ClientRegister from "./pages/client/Register";
import ClientActivate from "./pages/client/Activate";
import ClientDashboard from "./pages/client/Dashboard";
import AdminDashboard from "./pages/admin/Dashboard";
import AdminBookings from "./pages/admin/Bookings";
import SchoolDashboard from "./pages/school/Dashboard";
import SchoolRegister from "./pages/school/Register";
import SchoolPackages from "./pages/school/Packages";
import ScheduledBookings from "./pages/school/ScheduledBookings";
import AddPackage from "./pages/school/AddPackage";
import EditPackage from "./pages/school/EditPackage";
import SchoolLogin from "./pages/school/Login";
import ClientLogin from "./pages/client/Login";
import AdminLogin from "./pages/admin/Login";
import StepForm from "./components/StepForm";
import NewBookings from "./pages/school/NewBookings";
import DetailedNewBookings from "./pages/school/DetailedNewBookings";
import EditBooking from "./pages/school/EditBooking";
import { RequireAuth } from "react-auth-kit";
import PendingSchools from "./pages/admin/PendingSchool";
import ApprovedSchool from "./pages/admin/ApprovedSchool";
import AdminQuotes from "./pages/admin/AdminQuotes";
import Quotes from "./pages/school/Quotes";
import DetailedScheduledBookings from "./pages/school/DetailedScheduledBookings";
import DetailedPackages from "./pages/school/DetailedPackages";
import ClientDetailedScheduledBookings from "./pages/client/ClientDetailedScheduledBookings";
import ClientScheduledBookings from "./pages/client/ClientScheduledBookings";
import Return from "./pages/client/Return";
import Cancel from "./pages/client/Cancel";
import ClientBookings from "./pages/client/ClientBookings";
import ClientDetailedBookings from "./pages/client/ClientDetailedBookings";
import {
  CLIENT_RELATIVE_PATH,
  SCHOOL_RELATIVE_PATH,
  ADMIN_RELATIVE_PATH,
} from "./config/config";
import RoadSignsBooklets from "./pages/client/RoadSignsBooklets";
import RoadSignsBooklet1 from "./pages/client/RoadSignsBooklet1";
import LearnersTest from "./pages/client/LearnersTest";
import PrivacyPolicy from "./pages/client/PrivacyPolicy";
import SchoolPrivacyPolicy from "./pages/school/PrivacyPolicy";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ActiveClients from "./pages/admin/ActiveClients";
import DeactiveClients from "./pages/admin/DeactiveClients";
import UnregisteredClients from "./pages/admin/UnregisteredClients";
import Subscribe from "./pages/client/Subscribe";
import AutoSubscribe from "./pages/client/AutoSubscribe";
import Subscriptions from "./pages/admin/Subscriptions";
import LearningVideos from "./pages/client/LearningVideos";
import ClientForgotPassword from "./pages/client/ClientForgotPassword";
import TrainingVideos from "./pages/admin/TrainingVideos";
import AddVideo from "./pages/admin/AddVideo";
import StudyMaterial from "./pages/admin/StudyMaterial";
import AddStudyMaterial from "./pages/admin/AddStudyMaterial";
function App() {
  return (
    <Router>
      <ToastContainer />
      <div style={{ backgroundColor: "#EAEAEA" }}>
        <Routes>
          {/* Client routes */}
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<ClientLogin />} />
          <Route path="/client-data-policy" element={<PrivacyPolicy />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth loginPath="/login">
                <ClientDashboard />
              </RequireAuth>
            }
          />
          <Route path="/register" element={<ClientRegister />} />
          <Route path="/forgot-password" element={<ClientForgotPassword />} />
          <Route
            path="/activate/:email/:activation_code"
            element={<ClientActivate />}
          />
          <Route
            path="/driving-schools/province/:province"
            element={<Schools />}
          />
          <Route
            path={`${CLIENT_RELATIVE_PATH}/quote/learners`}
            element={<QuoteLearners />}
          />
          <Route
            path={`${CLIENT_RELATIVE_PATH}/quote/licence`}
            element={<QuoteLicence />}
          />

          {/* <Route path={`${CLIENT_RELATIVE_PATH}/booking/school/:schoolID/:packageType/:packageId' element={
          <RequireAuth loginPath='/login'>
            <Booking />
          </RequireAuth>
          }/>  */}

          {/* <Route
          path="/client/booking/school/:schoolID/:packageType/:packageId/"
          element={<PrivateRoute element={<Booking />} />}
        /> */}

          <Route
            path={`${CLIENT_RELATIVE_PATH}/booking/school/:schoolID/:packageType/:packageID/:bookingID?`}
            element={
              <PrivateRoute>
                <Booking />
              </PrivateRoute>
            }
          />

          <Route path={`${CLIENT_RELATIVE_PATH}/return`} element={<Return />} />
          <Route path={`${CLIENT_RELATIVE_PATH}/cancel`} element={<Cancel />} />
          {/* Road Signs Test */}
          <Route
            path={`${CLIENT_RELATIVE_PATH}/road-signs-booklets`}
            element={<RoadSignsBooklets />}
          />
          <Route
            path={`${CLIENT_RELATIVE_PATH}/road-signs-b1`}
            element={<RoadSignsBooklet1 />}
          />
          {/* old  test routes*/}
          <Route path={"/learners-test-1"} element={<LearnersTest />} />
          <Route
            path={`${CLIENT_RELATIVE_PATH}/learners-test-1`}
            element={
              <PrivateRoute>
                <LearnersTest />
              </PrivateRoute>
            }
          />
          <Route
            path={`${CLIENT_RELATIVE_PATH}/learners-tests/:testType`}
            element={
              <PrivateRoute>
                <LearnersTest />
              </PrivateRoute>
            }
          />
          <Route
            path={"/learners-tests/:testType"}
            element={<LearnersTest />}
          />
          <Route
            path={`${CLIENT_RELATIVE_PATH}/learners-tests/:testType`}
            element={
              <PrivateRoute>
                <LearnersTest />
              </PrivateRoute> 
            }
          />

          <Route
            path={`${CLIENT_RELATIVE_PATH}/training-videos`}
            element={
              
                <LearningVideos />
            }
          />

          <Route
            path={`${CLIENT_RELATIVE_PATH}/subscribe/:product?`}
            element={
              <PrivateRoute>
                <Subscribe />
              </PrivateRoute>
            }
          />

          <Route
            path={`${CLIENT_RELATIVE_PATH}/auto-subscribe`}
            element={
              <PrivateRoute>
                <AutoSubscribe />
              </PrivateRoute>
            }
          />

          <Route
            path={`${CLIENT_RELATIVE_PATH}/bookings`}
            element={
              <RequireAuth loginPath="/login">
                <ClientBookings />
              </RequireAuth>
            }
          />
          <Route
            path={`${CLIENT_RELATIVE_PATH}/bookings/:type`}
            element={
              <RequireAuth loginPath="/login">
                <ClientDetailedBookings />
              </RequireAuth>
            }
          />

          <Route
            path={`${CLIENT_RELATIVE_PATH}/scheduled-bookings`}
            element={
              <RequireAuth loginPath="/login">
                <ClientScheduledBookings />
              </RequireAuth>
            }
          />
          <Route
            path={`${CLIENT_RELATIVE_PATH}/scheduled-bookings/:type`}
            element={
              <RequireAuth loginPath="/login">
                <ClientDetailedScheduledBookings />
              </RequireAuth>
            }
          />

          {/* Admin routes */}
          <Route
            path={`${ADMIN_RELATIVE_PATH}/login`}
            element={<AdminLogin />}
          />
          <Route
            path={ADMIN_RELATIVE_PATH}
            element={
              <RequireAuth loginPath={`${ADMIN_RELATIVE_PATH}/login`}>
                <AdminDashboard />
              </RequireAuth>
            }
          />
          <Route
            path={`${ADMIN_RELATIVE_PATH}/dashboard`}
            element={
              <RequireAuth loginPath={`${ADMIN_RELATIVE_PATH}/login`}>
                <AdminDashboard />
              </RequireAuth>
            }
          />
          <Route
            path={`${ADMIN_RELATIVE_PATH}/bookings/total`}
            element={
              <RequireAuth loginPath={`${ADMIN_RELATIVE_PATH}/login`}>
                <AdminBookings />
              </RequireAuth>
            }
          />
          <Route
            path={`${ADMIN_RELATIVE_PATH}/schools/status/pending`}
            element={
              <RequireAuth loginPath={`${ADMIN_RELATIVE_PATH}/login`}>
                <PendingSchools />
              </RequireAuth>
            }
          />
          <Route
            path={`${ADMIN_RELATIVE_PATH}/schools/status/approved`}
            element={
              <RequireAuth loginPath={`${ADMIN_RELATIVE_PATH}/login`}>
                <ApprovedSchool />
              </RequireAuth>
            }
          />

          <Route
            path={`${ADMIN_RELATIVE_PATH}/training-videos/`}
            element={
              <RequireAuth loginPath={`${ADMIN_RELATIVE_PATH}/login`}>
                <TrainingVideos />
              </RequireAuth>
            }
          />


          <Route
            path={`${ADMIN_RELATIVE_PATH}/add-video`}
            element={
              <RequireAuth loginPath={`${ADMIN_RELATIVE_PATH}/login`}>
                <AddVideo />
              </RequireAuth>
            }
          />

          <Route
            path={`${ADMIN_RELATIVE_PATH}/study-material/`}
            element={
              <RequireAuth loginPath={`${ADMIN_RELATIVE_PATH}/login`}>
                <StudyMaterial />
              </RequireAuth>
            }
          />

          <Route
            path={`${ADMIN_RELATIVE_PATH}/add-study-material`}
            element={
              <RequireAuth loginPath={`${ADMIN_RELATIVE_PATH}/login`}>
                <AddStudyMaterial />
              </RequireAuth>
            }
          />

          <Route
            path={`${ADMIN_RELATIVE_PATH}/clients/status/active`}
            element={
              <RequireAuth loginPath={`${ADMIN_RELATIVE_PATH}/login`}>
                <ActiveClients />
              </RequireAuth>
            }
          />

          <Route
            path={`${ADMIN_RELATIVE_PATH}/clients/status/deactive`}
            element={
              <RequireAuth loginPath={`${ADMIN_RELATIVE_PATH}/login`}>
                <DeactiveClients />
              </RequireAuth>
            }
          />

          <Route
            path={`${ADMIN_RELATIVE_PATH}/clients/unregistered`}
            element={
              <RequireAuth loginPath={`${ADMIN_RELATIVE_PATH}/login`}>
                <UnregisteredClients />
              </RequireAuth>
            }
          />

          <Route
            path={`${ADMIN_RELATIVE_PATH}/test-subscriptions`}
            element={
              <RequireAuth loginPath={`${ADMIN_RELATIVE_PATH}/login`}>
                <Subscriptions />
              </RequireAuth>
            }
          />

          <Route
            path={`${ADMIN_RELATIVE_PATH}/leads/total`}
            element={
              <RequireAuth loginPath={`${ADMIN_RELATIVE_PATH}/login`}>
                <AdminQuotes />
              </RequireAuth>
            }
          />

          {/* Driving school routes */}
          <Route
            path={`${SCHOOL_RELATIVE_PATH}/school-data-policy`}
            element={<SchoolPrivacyPolicy />}
          />
          <Route path="/driving-schools/:id" element={<AboutSchool />} />
          <Route
            path={SCHOOL_RELATIVE_PATH}
            element={
              <RequireAuth loginPath={`${SCHOOL_RELATIVE_PATH}/login`}>
                <SchoolDashboard />
              </RequireAuth>
            }
          />
          <Route
            path={`${SCHOOL_RELATIVE_PATH}/dashboard`}
            element={
              <RequireAuth loginPath={`${SCHOOL_RELATIVE_PATH}/login`}>
                <SchoolDashboard />
              </RequireAuth>
            }
          />
          <Route
            path={`${SCHOOL_RELATIVE_PATH}/new-bookings`}
            element={
              <RequireAuth loginPath={`${SCHOOL_RELATIVE_PATH}/login`}>
                <NewBookings />
              </RequireAuth>
            }
          />
          <Route
            path={`${SCHOOL_RELATIVE_PATH}/new-bookings/:type`}
            element={
              <RequireAuth loginPath={`${SCHOOL_RELATIVE_PATH}/login`}>
                <DetailedNewBookings />
              </RequireAuth>
            }
          />
          <Route
            path={`${SCHOOL_RELATIVE_PATH}/edit-booking/:bookingId/:bookingType`}
            element={
              <RequireAuth loginPath={`${SCHOOL_RELATIVE_PATH}/login`}>
                <EditBooking />
              </RequireAuth>
            }
          />

          <Route
            path={`${SCHOOL_RELATIVE_PATH}/login`}
            element={<SchoolLogin />}
          />
          <Route
            path={`${SCHOOL_RELATIVE_PATH}/register`}
            element={<SchoolRegister />}
          />

          <Route
            path={`${SCHOOL_RELATIVE_PATH}/scheduled-bookings`}
            element={
              <RequireAuth loginPath={`${SCHOOL_RELATIVE_PATH}/login`}>
                <ScheduledBookings />
              </RequireAuth>
            }
          />
          <Route
            path={`${SCHOOL_RELATIVE_PATH}/scheduled-bookings/:type`}
            element={
              <RequireAuth loginPath={`${SCHOOL_RELATIVE_PATH}/login`}>
                <DetailedScheduledBookings />
              </RequireAuth>
            }
          />
          <Route
            path={`${SCHOOL_RELATIVE_PATH}/packages`}
            element={
              <RequireAuth loginPath={`${SCHOOL_RELATIVE_PATH}/login`}>
                <SchoolPackages />
              </RequireAuth>
            }
          />
          <Route
            path={`${SCHOOL_RELATIVE_PATH}/packages/:type`}
            element={
              <RequireAuth loginPath={`${SCHOOL_RELATIVE_PATH}/login`}>
                <DetailedPackages />
              </RequireAuth>
            }
          />
          <Route
            path={`${SCHOOL_RELATIVE_PATH}/add-package/:packageName`}
            element={
              <RequireAuth loginPath={`${SCHOOL_RELATIVE_PATH}/login`}>
                <AddPackage />
              </RequireAuth>
            }
          />
          <Route
            path={`${SCHOOL_RELATIVE_PATH}/edit-package/:packageId/:packageType`}
            element={
              <RequireAuth loginPath={`${SCHOOL_RELATIVE_PATH}/login`}>
                <EditPackage />
              </RequireAuth>
            }
          />

          <Route
            path={`${SCHOOL_RELATIVE_PATH}/quotes`}
            element={
              <RequireAuth loginPath={`${SCHOOL_RELATIVE_PATH}/login`}>
                <Quotes />
              </RequireAuth>
            }
          />

          <Route path="/test" element={<StepForm />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
