import { Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { getMetaData } from "../../lib/helperFunctions";
import { toast } from 'react-toastify';
import { MdAddCircle } from 'react-icons/md';
import EnhancedTable from "../../components/EnhancedTable";
import ClientsTable from '../../components/ClientsTable';
import { authenticatedPatchRequest } from '../../requests/apiRequests';
import useSubscriptions from "../../hooks/useSubscriptions";
import { useAuthUser, useAuthHeader } from "react-auth-kit";
import { useFetchAuth, useCheckSchool, useCheckClient } from "../../hooks/auth";
import { ADMIN_RELATIVE_PATH } from '../../config/config';
import NavBar from './NavBar';

const styles = {
  body: {
    padding: "100px 50px 50px 50px",
  },
};

function Subscriptions() {
  useFetchAuth();
  useCheckSchool(); 
  useCheckClient();
  
  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  const [currentPage, setCurrentPage] = useState(1);
  const meta = getMetaData("Admin | Partner with SimplifyDrive");
  const {subscriptions, subscriptionsLength} = useSubscriptions(authHeader());

  // Function to handle page changes
  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  // Function to handle marking subscriptions as paid
  const onPaidSubscriptions = async (id) => {
    const formFields = {};
    const response = await authenticatedPatchRequest(`/clients/activate/subscription/${id}`, formFields, authHeader())
    
    if(response.status === 200){
      toast.success('Status changed to paid! ' + response.status, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000
      });
    }
  }

  // Function to filter the latest subscriptions for each client and subscription_name
  const filterLatestSubscriptions = (subscriptions) => {
    const latestSubscriptions = {};

    subscriptions.forEach(subscription => {
      const key = `${subscription.client_ID}-${subscription.subscription_name}`;
      if (!latestSubscriptions[key] || new Date(subscription.date_subscribed) > new Date(latestSubscriptions[key].date_subscribed)) {
        latestSubscriptions[key] = subscription;
      }
    });

    return Object.values(latestSubscriptions);
  }

  // Function to process subscriptions data for display
  const SubscriptionsData = (subscriptions, onPaidSubscriptions) => {
    const filteredSubscriptions = filterLatestSubscriptions(subscriptions);

    return filteredSubscriptions.map(subscription => ({
      name: subscription.name,
      surname: subscription.surname,
      type: subscription.subscription_name,
      cell_number: subscription.cell_number,
      email: subscription.email,
      price: subscription.price,
      paid: subscription.paid,
      date: subscription.date_subscribed,
      // Uncomment the following lines if actions are needed
      // actions: (
      //   <>
      //     {subscription.paid ? (
      //       <button className="btn btn-outline-success border border-success m-1">
      //         <i className="fa fa-tick" aria-hidden="true"></i> Paid!
      //       </button>
      //     ) : (
      //       <button onClick={() => onPaidSubscriptions(subscription.id)} className="btn btn-outline-danger border border-danger m-1">
      //         <i className="fa fa-tick" aria-hidden="true"></i> Make it paid!
      //       </button>
      //     )}
      //   </>
      // ),
    }));
  };

  return (
    <>
      <NavBar />
      <div className="App">
        <div className="dashboard-container">
          <div className="dashboard-header">
            <div className="dashboard-links">
              <Link to={ADMIN_RELATIVE_PATH} className="dashboard-link">
                Dashboard
              </Link>
              <span className="dashboard-separator">/</span>
              <Link className="current-link">
                Subscriptions
              </Link>
            </div>
            <Link className="dashboard-link">
              <MdAddCircle size={35} />
            </Link>
          </div>
          {subscriptionsLength && (
            <ClientsTable 
              data={SubscriptionsData(subscriptions, onPaidSubscriptions)}
              columns={Object.keys(SubscriptionsData(subscriptions, onPaidSubscriptions)[0])}
              title="Subscriptions"
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />    
          )}
        </div>
      </div>
    </>
  );
}

export default Subscriptions;
