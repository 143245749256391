import React from "react";
import SubscribeForm from "../../components/SubscribeForm";
import NavBar from "./NavBar";
import { useParams } from "react-router-dom";

function Subscribe() {
  const { product } = useParams();  // Destructure to get the product parameter
  return (
    <>
      <NavBar />
      <div className="App">
        <SubscribeForm product={product} /> 
      </div>
    </>
  );
}

export default Subscribe;
