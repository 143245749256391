import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import background from "../../assets/images/background.png";
import DocumentMeta from "react-document-meta";
import { getMetaData } from "../../lib/helperFunctions";
import CustomButton from "../../components/CustomButton";
import NavBar from "./NavBar";
import { Form } from "react-bootstrap";
import Input from "../../components/Input";
import { postRequest } from "../../requests/apiRequests";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const styles = {
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: `url(${background})`,
    backgroundPosition: "top",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100vw",
    height: "100vh",
  },
  title: {
    color: "white",
    textShadow: "3px 3px 12px #FB2576",
    fontSize: "4vw",
    textAlign: "center",
    marginTop: "22vh",
  },
  form: {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    padding: "20px 15px",
    borderRadius: "8px",
    width: "380px",
    margin: "2em 0",
  },
};

function ClientForgotPassword() {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");

  const [otp, setOTP] = useState("");

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const meta = getMetaData("Recover Client Password");

  const nextStep = () => setStep((prev) => prev + 1);

  const onSubmit = async (e) => {
    e.preventDefault();
    const credentials = { email: email, type: "c" };
    try {
      const response = await postRequest("/users/forgot-password", credentials);
      const json = await response?.json();
      console.log(response);

      if (response.status === 200) {
        toast.success("Email is valid, now verify email, an OTP is sent to your email!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        nextStep();

      } else {
        console.log("Failed to recover");
        toast.error("Recovery failed. Email do not match!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      }

    } catch (error) {
      console.error(error);
      toast.error("Recovery failed. Email do not match!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    }
  };


  const onSubmitOTP = async (e) => {
    e.preventDefault();
    const credentials = { email: email, otp: otp, type: "c" };
    try {
      const response = await postRequest("/users/verify-otp", credentials);
      const json = await response?.json();
      console.log(response);

      if (response.status === 200) {
        toast.success("OTP is valid, now change password!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        nextStep();

      } else {
        console.log("OTP verification failed. OTP is invalid, used or expired!");
        toast.error("OTP verification failed. OTP is invalid, used or expired!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      }

    } catch (error) {
      console.error(error);
      toast.error("OTP verification failed. OTP is invalid, used or expired!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    }
  };




  const onSubmitCP = async (e) => {
    e.preventDefault();

    if(password1 != password2){
      toast.error("Passsword do not match, please try again!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });

      return;
    }

    const credentials = { email: email, password: password1, otp: otp, type: "c" };
    try {
      const response = await postRequest("/users/change-password", credentials);
      const json = await response?.json();
      console.log(response);

      if (response.status === 200) {

        toast.success("Password changed, you can now login!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });

        navigate("/login");

      } else {
        console.log("We could not change password due to technical issues!");
        toast.error("We could not change password due to technical issues!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      }

    } catch (error) {
      console.error(error);
      toast.error("We could not change password due to technical issues!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    }
  };


  switch (step) {
    case 1:
      return (
    <>
      <NavBar />
      <div style={styles.body}>
        <DocumentMeta {...meta} />
        <h1 style={styles.title}>Forgot Password</h1>
        <Form style={styles.form}>
          <Input
            label="Enter your registred email"
            type="email"
            placeholder="Enter your registred email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <CustomButton text="Recover Password" onClick={onSubmit} />
        </Form>
      </div>
    </>
  )
  case 2:
      return (
        <>
          <NavBar />
          <div style={styles.body}>
            <h1 style={styles.title}>Verify Your OTP</h1>
            <Form style={styles.form}>
              <Input
                label="Enter the OPT sent to your email!"
                type="text"
                placeholder="Enter the OPT sent to your email!"
                onChange={(e) => {
                  setOTP(e.target.value);
                }}
                autocomplete="off"
              />
              <CustomButton text="Verify OTP" onClick={onSubmitOTP} />
            </Form>
          </div>
        </>
      )
  case 3:
    return (
        <>
          <NavBar />
          <div style={styles.body}>
            <DocumentMeta {...meta} />
            <h1 style={styles.title}>Change Your Password</h1>
            <Form style={styles.form}>
              <Input
                label="Enter New Password"
                type="password"
                placeholder="Enter New Password"
                onChange={(e) => {
                    setPassword1(e.target.value);
                }}
              />

              <Input
                label="Confirm Password"
                type="password"
                placeholder="Confirm Password"
                onChange={(e) => {
                    setPassword2(e.target.value); 
                }}
              />
              <CustomButton text="Change Password" onClick={onSubmitCP} />
            </Form>
          </div>
        </>
  )
default:
      return (<></>)
}
}

export default ClientForgotPassword;
