import { useState, useEffect } from 'react';
import { Form, Card, Button, Row, Col } from "react-bootstrap";
import { useAuthHeader } from 'react-auth-kit';
import Testimony from './Testimony';

const styles = {
    formContainer: { maxWidth: "700px", margin: "0 auto", padding: "20px", backgroundColor: "#f8f9fa", borderRadius: "10px" },
    heading: { textAlign: "center", marginBottom: "30px" },
    subHeading: { textAlign: "center", marginBottom: "20px", color: "#6c757d" },
    priceTag: { textAlign: "center", fontSize: "24px", fontWeight: "bold", color: "#28a745" },
    description: { textAlign: "center", marginBottom: "20px" },
    buttonContainer: { display: "flex", justifyContent: "center" },
    cardContainer: { marginTop: "30px" }
};

function WhatsAppGroupDetails({ onSubmit }) {
    const authHeader = useAuthHeader();

    useEffect(() => {
    }, []);

    return (
        <div style={styles.formContainer}>
            <h1 style={styles.heading}>Join Our Exclusive WhatsApp Group</h1>
            <h2 style={styles.priceTag}>Only R200</h2>
            <p style={styles.description}>
                Connect with like-minded individuals, receive personalized support, and gain access to exclusive content and updates. Don't miss out on this opportunity to be part of our community!
            </p>
            <div style={styles.buttonContainer}>
                <Button
                    href="https://wa.me/27761733641?text=Hey, %20I%20would%20like%20to%20join%20your%20SimplifyDrive%20WhatsApp%20group!"
                    target="_blank"
                    variant="success"
                >
                    Join WhatsApp Group
                </Button>
            </div>
            <div style={styles.cardContainer}>
                <Testimony slidesToShow={2} />
            </div>
        </div>
    );
}

export default WhatsAppGroupDetails;
