import React, { useState } from "react";
import { BASE_URL } from "../config/config";
import CustomModal from "./CustomModal";
import WhatsAppGroupDetails from './WhatsAppGroupDetails';

const styles = {
  cardContainer: {
    display: "flex",
    justifyContent: "left",
    gap: "20px",
    marginTop: "20px",
    flexWrap: "wrap",
  },
  card: {
    width: "300px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    overflow: "hidden",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    textDecoration: "none",
    color: "inherit",
  },
  cardImage: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
  },
  cardContent: {
    padding: "15px",
    textAlign: "left",
  },
  cardTitle: {
    fontSize: "20px",
    marginBottom: "10px",
  },
  cardDescription: {
    fontSize: "16px",
  },
  '@media (max-width: 768px)': {
    cardContainer: {
      flexDirection: "column",
      alignItems: "center",
    },
    card: {
      width: "100%"
    },
  },
};

function ProductCards() {

  const [showWGModal, setShowWGModal] = useState(false);

    const handleWGModal = () => {
        setShowWGModal(prev => !prev);
    }

    const handleSendWhatsApp = () => {
        handleWGModal();
    };
  return (
    <>
      {/* Advertising Links as Cards */}
      <h2 className="dashboard-title mt-3">Try Our Training Products Below</h2>
      <div style={styles.cardContainer}>
        <a href={`${BASE_URL}/client/training-videos`} style={styles.card}>
          <img src={require("../assets/images/hqdefault.webp")} alt="Training Videos" style={styles.cardImage} />
          <div style={styles.cardContent}>
            <h2 style={styles.cardTitle}>Training Videos</h2>
            <p style={styles.cardDescription}>Unlock your potential with our comprehensive training videos. Enhance your skills and knowledge with expert-guided content designed to help you succeed.</p>
          </div>
        </a>
        <a href={`${BASE_URL}/client/learners-tests/test1`} style={styles.card}>
          <img src={require("../assets/signs/Sketch-2.jpg")} alt="Learner's Test Code 8" style={styles.cardImage} />
          <div style={styles.cardContent}>
            <h2 style={styles.cardTitle}>Learner's Simulation Test Code 8</h2>
            <p style={styles.cardDescription}>Ace your Code 8 learner's test with our extensive library of practice questions and tests. Get the confidence you need to pass on your first try.</p>
          </div>
        </a>
        <a href={`${BASE_URL}/client/learners-tests/test2`} style={styles.card}>
          <img src={require("../assets/signs/Sketch-2.jpg")} alt="Learner's Test Code 10 & 14" style={styles.cardImage} />
          <div style={styles.cardContent}>
            <h2 style={styles.cardTitle}>Learner's Simulation Test Code 10 & 14</h2>
            <p style={styles.cardDescription}>Master the Code 10 & 14 learner's tests with our comprehensive practice resources. Prepare effectively and ensure your success.</p>
          </div>
        </a>
        <a onClick={handleSendWhatsApp} style={styles.card}>
          <img src={require("../assets/images/whatsapp.jpg")} alt="Join WhatsApp Group" style={styles.cardImage} />
          <div style={styles.cardContent}>
            <h2 style={styles.cardTitle}>Join WhatsApp Group</h2>
            <p style={styles.cardDescription}>Join our exclusive WhatsApp group for instant access to training resources for Code 8, 10 & 14. Stay updated with study materials, tips, and support from fellow learners.</p>
          </div>
        </a>
      </div>
      <CustomModal showModal={showWGModal} handleClose={handleWGModal} title="Join WhatsApp Group" bodyContent={<WhatsAppGroupDetails onSubmit={() => { setShowWGModal(false); }} />} />
    </>
  );
}

export default ProductCards;
